<template>
  <List
    title="Quext users"
    resource="quextUsers"
    :basePath="basePath"
    :request-params="{deletedOnly: true}"
    :fields="fields"
    track-by="profileId"
    ref="list"
    on-row-click="edit"
    :search="true"
    search-placeholder="Search for a user by name or email"
    :initial-filter="filter"
    :filter-mapper="mapFilterToQuery"
  >
    <template v-slot:filters="props">
      <div class="form-row">
        <div class="form-col">
          <SelectInput
            name="role"
            label="Role"
            :options="roles"
          />
        </div>
        <div class="form-col">
          <DateRangeInput
            label="Registered at"
            name="registeredAt"
            :max-date="today"
          />
        </div>
        <div class="form-col">
          <DateRangeInput
            label="Last login"
            name="lastLogin"
            position="right"
          />
        </div>
      </div>
      <div class="form-row justify-end">
        <button @click.stop="props.reset()" class="btn-primary btn-transparent mr-8">Reset</button>
        <button class="btn-primary">Filter</button>
      </div>
    </template>
  </List>
</template>

<script>
  import {get} from 'lodash-es';
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import DateRangeFilterMixin from '@/components/auth/DateRangeFilterMixin';
  import List from '@/components/auth/list/List';
  import DateTimeField from '@/components/auth/list/fields/DateTimeField';
  import SelectInput from '@/components/form/SelectInput';
  import DateRangeInput from '@/components/form/DateRangeInput';
  import UserField from '@/components/auth/list/fields/UserField';

  export default {
  name: 'QuextUsers',
  components: {
    SelectInput,
    List,
    DateRangeInput,
  },
  mixins: [ModalNavigation, NotifyMixin, ConfirmationMixin, DateRangeFilterMixin],
  data: function () {
    return {
      roles: [],
      filter: {
        lastLogin: {
          startDate: null,
          endDate: null,
        },
        registeredAt: {
          startDate: null,
          endDate: null,
        },
      },
      fields: [
        {
          name: UserField,
          title: 'Name',
          sortField: 'fullName'
        },
        {
          name: 'role',
          sortField: 'role',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: DateTimeField,
          sortField: 'createdAt',
          title: 'Registered',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'createdAt',
            showTime: false,
          }
        },
        {
          name: DateTimeField,
          sortField: 'lastLogin',
          title: 'Last login',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'lastLogin'
          }
        },
        {
          name: DateTimeField,
          sortField: 'deletedAt',
          title: 'Deleted at',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'deletedAt'
          }
        },
      ],
    };
  },
  computed: {
    basePath: function () {
      return this.$route.path;
    },
  },
  created() {
    this.$authDataProvider.getList('quextUsersRoles')
      .then((roles) => {
        this.roles = roles.map(({name: value, value: key}) => ({key, value}));
      })
      .catch((err) => this.notifyError(err));
  },
  methods: {
    mapFilterToQuery(values) {
      const { from: lastLoginFrom, to: lastLoginTo } = this.getFilterDateRange(values.lastLogin);
      const { from: registrationFrom, to: registrationTo } = this.getFilterDateRange(values.registeredAt);

      return {
        role: get(values, 'role'),
        lastLoginFrom,
        lastLoginTo,
        registrationFrom,
        registrationTo,
      }
    },
  }
};
</script>

<style>

</style>
